import axios from 'axios';
var ConfiguratorService = /** @class */ (function () {
    function ConfiguratorService() {
    }
    ConfiguratorService.prototype.getConfigurator = function () {
        return axios.get('/api/ivy-configurators/v1/configurator/consolidated/', {
            withCredentials: true,
        });
    };
    ConfiguratorService.prototype.saveAndClearConfigurator = function () {
        return axios.patch('/api/ivy-configurators/v1/configurator/consolidated/', {}, {
            withCredentials: true,
        });
    };
    ConfiguratorService.prototype.setValue = function (key, value) {
        return axios.post('/api/ivy-configurators/v1/configurator/consolidated/values', {
            key: key,
            value: value
        }, {
            withCredentials: true,
        });
    };
    ConfiguratorService.prototype.popValue = function () {
        return axios.delete('/api/ivy-configurators/v1/configurator/consolidated/values', {
            withCredentials: true,
        });
    };
    ConfiguratorService.prototype.retrieveFromReference = function (reference) {
        return axios.put("/api/ivy-configurators/v1/configurator/consolidated/".concat(reference), {}, {
            withCredentials: true,
        });
    };
    return ConfiguratorService;
}());
export default new ConfiguratorService();
